import { InjectionToken } from '@angular/core';
import {
  ApolloCache,
  ApolloClient,
  ApolloQueryResult,
  DefaultContext,
  FetchResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from '@apollo/client/core';
import { Observable } from 'rxjs';

export interface GraphqlConfig {
  anonymousGraphqlEndpoint: string;
  securedGraphqlEndpoint: string;
}
export type GraphqlConfigProvider = () => GraphqlConfig;

export type GraphqlClient = Omit<ApolloClient<any>, 'query' | 'mutate'> & {
  query: <T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>,
  ) => Observable<ApolloQueryResult<T>>;
  mutate: <
    TData = any,
    TVariables = OperationVariables,
    TContext = DefaultContext,
    TCache extends ApolloCache<any> = ApolloCache<any>,
  >(
    options: MutationOptions<TData, TVariables, TContext, ApolloCache<any>>,
  ) => Observable<FetchResult<TData, Record<string, any>, Record<string, any>>>;
};

export interface GraphQlClientLoader {
  getAnonymousClient: () => GraphqlClient;
  getSecuredClient: () => GraphqlClient;
}

export const GRAPHQL_CLIENT_LOADER = new InjectionToken<GraphQlClientLoader>('graphQLClientLoader');
export const GRAPHQL_CONFIG = new InjectionToken<GraphqlConfigProvider>('graphQLEndpoint');
