import { InMemoryCache } from '@apollo/client/core';
const cache = new InMemoryCache();

export function getApolloCache(): InMemoryCache {
  return cache;
}

// export const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo.cache');

// export const APOLLO_CACHE_PROVIDER = { provide: APOLLO_CACHE, useValue: cache };
