import { UserProfile } from './authentication';
import {
  InsuranceProvider,
  InsuranceProvider_FullName,
  Product,
  ProductSFCode,
  SUPPORTED_LEAD_SOURCE,
  SUPPORTED_STATE,
  WhiteLabelPartner,
} from './global';

export interface LeadUtmParameters {
  utm_source?: string;
  msclkid?: string;
  gclid?: string;
  atrkid?: string;
  e_campaign_id?: string;
  e_adset_id?: string;
  e_ad_id?: string;
  utm_term?: string;
  utm_content?: string;
  utm_campaign?: string;
  utm_medium?: string;
  lead_source: string;
  state?: string;
  carrier?: string;
}

export enum ConversionType {
  QUOTE = 'Quote form fill',
  QUOTE_SELF_SERVE = 'Quote form fill - SS',
  STANDARD = 'Form fill',
  PHONE_CLICK = 'phone click',
  SCHEDULE = 'Schedule',
}

export type VariableLeadHiddenParameters = {
  state?: string;
  carrier: string;
  product: ProductSFCode;
  oid?: string;
  selfServe?: boolean;
  contactType: ConversionType; // Modern Form fill or quote fill
  retURL?: string;
  landingPageUrl?: string;
  landingPageVersion?: string | undefined;
  channelType?: ChannelType | undefined;
  '00N5e00000WhU5Y'?: string; //Legacy Form fill or quote fill
} & LeadUtmParameters;

export interface StaticLeadHiddenParameters {
  company: string;
  debugEmail: string;
  debug: string;
}

export type LeadHiddenParameters = VariableLeadHiddenParameters & StaticLeadHiddenParameters;

export interface UserLeadInfo {
  firstname: string;
  lastname: string;
  email: string;
  mobile: string;
  state?: string;
  zipCode?: number;
}

export enum TdiFemaleRatio {
  '0_25' = '0-25%',
  '26_50' = '26-50%',
  '51_60' = '51-75%',
  '61_75' = '61-75%',
  '76_80' = '76-80%',
  '80+' = '+81%',
}

export const TdiFemaleRatios = Object.values(TdiFemaleRatio);

export enum WAGE_FREQUENCY {
  ANNUALLY = 'Annually',
  WEEKLY = 'Weekly',
  BI_WEEKLY = 'Bi-Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}

export const DEFAULT_WAGE_FREQUENCY = WAGE_FREQUENCY.ANNUALLY;

export enum TDI_ADDITIONAL_BENEFIT {
  NONE = 'None',
  RIDER_60 = '60 Rider',
  RIDER_65 = '65 Rider',
}

export const TDI_MAX_WAGE = {
  [WAGE_FREQUENCY.ANNUALLY]: {
    [TDI_ADDITIONAL_BENEFIT.NONE]: 68560.96,
    [TDI_ADDITIONAL_BENEFIT.RIDER_60]: 130000,
    [TDI_ADDITIONAL_BENEFIT.RIDER_65]: 200000,
  },
  [WAGE_FREQUENCY.WEEKLY]: {
    [TDI_ADDITIONAL_BENEFIT.NONE]: 1318.48,
    [TDI_ADDITIONAL_BENEFIT.RIDER_60]: 2500,
    [TDI_ADDITIONAL_BENEFIT.RIDER_65]: 3846.15,
  },
  [WAGE_FREQUENCY.BI_WEEKLY]: {
    [TDI_ADDITIONAL_BENEFIT.NONE]: 2636.96,
    [TDI_ADDITIONAL_BENEFIT.RIDER_60]: 5000,
    [TDI_ADDITIONAL_BENEFIT.RIDER_65]: 7692.3,
  },
  [WAGE_FREQUENCY.MONTHLY]: {
    [TDI_ADDITIONAL_BENEFIT.NONE]: 5713.41,
    [TDI_ADDITIONAL_BENEFIT.RIDER_60]: 10833.33,
    [TDI_ADDITIONAL_BENEFIT.RIDER_65]: 16666.67,
  },
  [WAGE_FREQUENCY.QUARTERLY]: {
    [TDI_ADDITIONAL_BENEFIT.NONE]: 17140.24,
    [TDI_ADDITIONAL_BENEFIT.RIDER_60]: 32500,
    [TDI_ADDITIONAL_BENEFIT.RIDER_65]: 50000,
  },
};

export const TDI_DEFAULT_ADDITIONAL_BENEFIT = TDI_ADDITIONAL_BENEFIT.NONE;
export const TDI_DEFAULT_DURATION = '26';
export const TDI_DEFAULT_FEMALE_RATIO = TdiFemaleRatio['51_60'];
export const TDI_DEFAULT_EMPLOYEE_COUNT = 15;

export interface TdiQuoteInputs {
  femaleRatio: TdiFemaleRatio;
  coveredWages: number;
  wageFrequency: WAGE_FREQUENCY;
  coveredWeeks: '26' | '52';
  additionalBenefit: TDI_ADDITIONAL_BENEFIT;
}

export type TdiLeadInfo = {
  firstname: string;
  lastname: string;
  email: string;
  mobile: string;
  company: string;
  employeeCount: number;
} & TdiQuoteInputs;

export type UserLeadQuoteInfo = UserLeadInfo & {
  age: string;
  dateOfBirth?: string;
  coverage: number;
  gender: string;
  smoker: boolean;
  termFromAd?: number;
};

export type UserLeadInfoWithConsent = UserLeadInfo & { hasAgreed: boolean };

export type UserLeadFormInfo = {
  [key in keyof UserLeadInfo]: {
    value: UserLeadInfo[key];
    dirty: boolean;
    isValid: () => boolean;
  };
};

export type TdiLeadformInfo = {
  [key in keyof TdiLeadInfo]: {
    value: TdiLeadInfo[key];
    dirty: boolean;
    isValid: () => boolean;
  };
};

export type UserLeadQuoteFormInfo = {
  [key in keyof UserLeadQuoteInfo]: {
    value: UserLeadQuoteInfo[key];
    dirty: boolean;
    isValid: () => boolean;
  };
};

export type UserLeadFormInfoWithConsent = {
  [key in keyof UserLeadInfoWithConsent]: {
    value: UserLeadInfoWithConsent[key];
    dirty: boolean;
    isValid: () => boolean;
  };
};

export type Lead = UserLeadInfo & LeadHiddenParameters;
export enum ChannelType {
  o8Web = 'O8Web', // Landing Pages
  o8Website = 'O8Website',
}

export const SUPPORTED_CHANNEL_TYPES = [ChannelType.o8Web, ChannelType.o8Website];

export interface SubmittedLeadParams {
  state: SUPPORTED_STATE;
  carrier: InsuranceProvider;
  product: Product;
  leadSource: SUPPORTED_LEAD_SOURCE | 'Unknown';
  whiteLabelPartner?: WhiteLabelPartner | undefined;
  ssRedirectUrl?: string;
  ssToken?: string;
  visitorId?: string;
}

export enum LEAD_STATUS {
  NEW = 'New',
  CONTACTING = 'Contacting',
  HELPING = 'Helping',
  APPLICATION = 'Application',
  PROTECTED = 'Protected',
  DROPPED = 'Dropped',
}

export interface OpenLead {
  externalIdentifier: string;
  salesforceIdLeadOwner: string;
  leadStatus: LEAD_STATUS;
  advocateNote: string;
  name: string;
  lastName: string;
  firstName: string;
  pod: string;
  leadCreatedTime: Date;
  carrierFromAd: string;
  channelType: string;
  leadSource: InsuranceProvider_FullName;
  productFromAd: ProductSFCode;
  stateFromAd: SUPPORTED_STATE;
  lastCallResult: string;
  lastCallTime: Date;
  nonVarPolicySentDate: Date;
  kitSentDate: Date;
  currentPosition: string;
  positionDetail: string;
  policyEffectiveDate: Date;
  rating: string;
  nextFollowUpDate: Date;
  leadTakenTimestamp: Date;
  isLeadAutoAssignment: boolean;
  contactor?: string | null; // Contactor user sf id
  billableWarmTransfer?: boolean;
  firstCallAdvocate?: string;
  firstCallResult?: string;
  firstCallTime?: Date;
  firstPaymentDate?: Date;
  firstYearCommission?: number;
  protected_time?: Date;
  protectionDate?: Date;
  phone: string;
  billable?: boolean;
}

export interface CallLog {
  externalIdentifier: string;
  leadStatus: string;
  startTime: Date;
  endTime: Date;
  hangUpReason: string;
  uuid: string;
  advocateEmail: string;
  createdOn: Date;
  callType: string;
  disposition: string;
}

export interface SfTask {
  externalIdentifier: string;
  leadExternalIdentifier: string;
  externalSessionIdentifier: string;
  externalOwnerId: string;
  uuid: string;
  externalAgentIdentifier: string;
  callDisposition?: string;
  isDeleted: boolean;
  timestamp: Date;
  callLog?: CallLog;
  lead?: OpenLead;
  userInfo?: UserProfile;
}

export interface Pod {
  name: string;
  active: boolean;
}
