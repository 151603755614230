export const VIEWPORT_MOBILE_BREAKPOINT = 640; // In px
export const VIEWPORT_DESKTOP_BREAKPOINT = 1280; // In px
export const VIEWPORT_WIDE_SCREEN_BREAKPOINT = 1800; // In px

export enum O8_CUSTOM_HEADERS {
  USER_AUTHORIZATION = 'userauthorization',
  TRACE_ID = 'o8-trace-id',
  SESSION_ID = 'o8-session-id',
  IMPERSONATION = 'o8-impersonation', // Impersonation token encoded in base64
  IDEMPOTENCY = 'idempotency-key',
}

export const MIN_AGE_FINAL_EXPENSE = 50;
export const MIN_AGE_TERM_INSURANCE = 18;
export const DEFAULT_FINAL_COVERAGE = 10000;

export const DEFAULT_AGE_FINAL_EXPENSE = 65;
export const DEFAULT_AGE_TERM_INSURANCE = 35;
export const MAX_AGE_FINAL_EXPENSE = 85;
export const MAX_AGE_TERM_INSURANCE = 70;
export const MAX_AGE_TERM_INSURANCE_PNT_LUMICO = 65;

export const MIN_AGE_TERM_SIMPLE_INSURANCE_HAVENLIFE = 20;
export const MAX_AGE_TERM_SIMPLE_INSURANCE_HAVENLIFE = 55;
export const MAX_AGE_TERM_SIMPLE_INSURANCE_HAVENLIFE_TERM20 = 50;
export const MAX_AGE_TERM_INSURANCE_HAVENLIFE = 65;
export const MAX_AGE_TERM_INSURANCE_HAVENLIFE_TERM2530 = 50;
export const MAX_AGE_TERM_INSURANCE_PNT_LUMICO_TERM_30 = 50;
export const MAX_AGE_TERM_INSURANCE_PGL_TERM_15 = 65;
export const MAX_AGE_TERM_INSURANCE_PGL_TERM_20 = 60;
export const MIN_COVERAGE_FINAL_EXPENSE = 5000;
export const MAX_COVERAGE_FINAL_EXPENSE = 50000;
export const TERM_COVERAGE_STEP = 50000;
export const DEFAULT_TERM_COVERAGE = 500000;
export const MIN_TERM_INSURANCE_YEARS = 10;

export const MIN_COVERAGE_TERM_INSURANCE = 25000;
export const MAX_COVERAGE_TERM_INSURANCE = 1000000;
export const MAX_COVERAGE_TERM_INSURANCE_PNT_65 = 250000;

export const MIN_COVERAGE_TERM_INSURANCE_PGL = 100000;
export const MAX_COVERAGE_TERM_INSURANCE_PGL = 500000;

export const MIN_COVERAGE_NATIONWIDE_TERM_INSURANCE = 100000;
export const MAX_COVERAGE_NATIONWIDE_TERM_INSURANCE = 2000000;

export const MIN_COVERAGE_HAVEN_LIFE_TERM_INSURANCE = 250000;
export const MAX_COVERAGE_HAVEN_LIFE_TERM_INSURANCE = 5000000;

export const MIN_COVERAGE_HAVENLIFE_SIMPLE_INSURANCE = 25000;
export const MAX_COVERAGE_HAVENLIFE_SIMPLE_INSURANCE = 1000000;

export const DEFAULT_COVERAGE_GUARANTEED_FINAL_EXPENSE = 10000;
export const MIN_COVERAGE_GUARANTEED_FINAL_EXPENSE = 1500;
export const MAX_COVERAGE_GUARANTEED_FINAL_EXPENSE = 50000;
export const STEP_COVERAGE_GUARANTEED_FINAL_EXPENSE = 500;
export const DEFAULT_AGE_GUARANTEED_FINAL_EXPENSE = 65;
export const MIN_AGE_GUARANTEED_FINAL_EXPENSE = 50;
export const MAX_AGE_GUARANTEED_FINAL_EXPENSE = 85;
export const MIN_AGE_GUARANTEED_FINAL_EXPENSE_PENNSYLVANIA = 56;
export const MIN_AGE_GUARANTEED_FINAL_EXPENSE_DC = 55;
export const MAX_AGE_GUARANTEED_FINAL_EXPENSE_MINNESOTA = 75;
export const MAX_AGE_GUARANTEED_FINAL_EXPENSE_MISSOURI = 75;
export const MAX_AGE_GUARANTEED_FINAL_EXPENSE_NEW_JERSEY_MALE = 77;
export const MAX_AGE_GUARANTEED_FINAL_EXPENSE_NEW_JERSEY_FEMALE = 82;

export const STARTING_ADVOCATE_HOURS = 8;
export const ENDING_ADVOCATE_HOURS = 21;

export const PGL_TERM_YEARS = [10, 15, 20];
export const HAVENLIFE_TERM_YEARS = [10, 15, 20, 25, 30];
export const HAVENLIFE_SIMPLE_YEARS = [5, 10, 15, 20];
export const NATIONWIDE_TERM_YEARS = [10, 15, 20, 30];

export type StringKey<T> = Extract<keyof T, string>;

// Deprecated (use Carrier instead)
export enum InsuranceProvider {
  AMERICO = 'Americo',
  LUMICO = 'Lumico',
  COL_PEN = 'ColPenn',
  NATIONWIDE = 'Nationwide',
  PGL = 'PGL',
  FG = 'FG',
  WASHINGTON_NATIONAL = 'Washington National',
  HAVEN_LIFE = 'HavenLife',
  ASSURITY = 'Assurity',
  LEGAL_AND_GENERAL = 'Legal and General',
}
// Deprecated (use CARRIERS instead)
export const INSURANCE_PROVIDERS = Object.values(InsuranceProvider);

export enum InsuranceProvider_FullName {
  AMERICO = 'Americo',
  LUMICO = 'Lumico',
  COL_PEN = 'Colonial Penn',
  NATIONWIDE = 'Nationwide',
  PGL = 'Pacific Guardian Life',
  FG = 'F&G',
  WASHINGTON_NATIONAL = 'Washington National',
  HAVEN_LIFE = 'Haven Life',
  ASSURITY = 'Assurity',
  LEGAL_AND_GENERAL = 'Legal and General',
}

export const INSURANCE_PROVIDERS_FULLNAME = Object.values(InsuranceProvider_FullName);

export enum AdProvider {
  Rocketer = 'r',
  HappyCog = 'hc',
  Origin8 = 'o',
}

export const AD_PROVIDER = {
  // Using const Notation to leverage Mapped type in Typescript
  [AdProvider.Rocketer]: 'r',
  [AdProvider.HappyCog]: 'hc',
  [AdProvider.Origin8]: 'o',
};

export enum Product {
  TERM_19 = 'term19',
  TERM_SIMPLE = 'Term Simple', // Haven Life
  CRITICAL_ILLNESS = 'Critical Illness',
  FINAL = 'final',
  IUL = 'iul',
  MYGA = 'MYGA',
  TDI = 'tdi',
  DISABILITY_INCOME = 'Disability Income',
}

export const AVAILABLE_PRODUCTS = Object.values(Product);

export enum ProductSFCode {
  TERM_19 = 'term19',
  FINAL = 'sife', // Final Expense
  IUL = 'iul',
  CRITICAL_ILLNESS = 'Critical Illness',
  TERM_SIMPLE = 'termSimple',
  MYGA = 'MYGA', // Multi-Year Guaranteed Annuity
  TDI = 'tdi', // Temporary disability Insurance
  DISABILITY_INCOME = 'Disability Income',
}

export enum SUPPORTED_STATE {
  Alabama = 'AL',
  Georgia = 'GA',
  Texas = 'TX',
  Illinois = 'IL',
  Pennsylvania = 'PA',
  NorthCarolina = 'NC',
  Arizona = 'AZ',
  Ohio = 'OH',
  NewJersey = 'NJ',
  Oklahoma = 'OK',
  Tennessee = 'TN',
  Wisconsin = 'WI',
  SouthCarolina = 'SC',
  Colorado = 'CO',
  Connecticut = 'CT',
  Indiana = 'IN',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Virginia = 'VA',
  Maryland = 'MD',
  Minnesota = 'MN',
  Massachusetts = 'MA',
  Missouri = 'MO',
  Michigan = 'MI',
  California = 'CA',
  Mississippi = 'MS',
  Florida = 'FL',
  WestVirginia = 'WV',
  Montana = 'MT',
  Arkansas = 'AR',
  Vermont = 'VT',
  RhodeIsland = 'RI',
  Maine = 'ME',
  NewHampshire = 'NH',
  Kansas = 'KS',
  SouthDakota = 'SD',
  Nebraska = 'NE',
  NewMexico = 'NM',
  Idaho = 'ID',
  Nevada = 'NV',
  NorthDakota = 'ND',
  Iowa = 'IA',
  Washington = 'WA',
  Oregon = 'OR',
  Hawaii = 'HI',
  Alaska = 'AK',
  Utah = 'UT',
  Wyoming = 'WY',
  Delaware = 'DE',
  NewYork = 'NY',
  DistrictOfColumbia = 'DC',
}

export const SUPPORTED_STATE_FULLNAME = {
  [SUPPORTED_STATE.Texas]: 'Texas',
  [SUPPORTED_STATE.Delaware]: 'Delaware',
  [SUPPORTED_STATE.Alabama]: 'Alabama',
  [SUPPORTED_STATE.Georgia]: 'Georgia',
  [SUPPORTED_STATE.Illinois]: 'Illinois',
  [SUPPORTED_STATE.Pennsylvania]: 'Pennsylvania',
  [SUPPORTED_STATE.NorthCarolina]: 'North Carolina',
  [SUPPORTED_STATE.Arizona]: 'Arizona',
  [SUPPORTED_STATE.Ohio]: 'Ohio',
  [SUPPORTED_STATE.NewJersey]: 'New Jersey',
  [SUPPORTED_STATE.Oklahoma]: 'Oklahoma',
  [SUPPORTED_STATE.Tennessee]: 'Tennessee',
  [SUPPORTED_STATE.Wisconsin]: 'Wisconsin',
  [SUPPORTED_STATE.SouthCarolina]: 'South Carolina',
  [SUPPORTED_STATE.Colorado]: 'Colorado',
  [SUPPORTED_STATE.Connecticut]: 'Connecticut',
  [SUPPORTED_STATE.Indiana]: 'Indiana',
  [SUPPORTED_STATE.Kentucky]: 'Kentucky',
  [SUPPORTED_STATE.Louisiana]: 'Louisiana',
  [SUPPORTED_STATE.Virginia]: 'Virginia',
  [SUPPORTED_STATE.Maryland]: 'Maryland',
  [SUPPORTED_STATE.Minnesota]: 'Minnesota',
  [SUPPORTED_STATE.Massachusetts]: 'Massachusetts',
  [SUPPORTED_STATE.Missouri]: 'Missouri',
  [SUPPORTED_STATE.Michigan]: 'Michigan',
  [SUPPORTED_STATE.California]: 'California',
  [SUPPORTED_STATE.Mississippi]: 'Mississippi',
  [SUPPORTED_STATE.Florida]: 'Florida',
  [SUPPORTED_STATE.WestVirginia]: 'West Virginia',
  [SUPPORTED_STATE.Montana]: 'Montana',
  [SUPPORTED_STATE.Arkansas]: 'Arkansas',
  [SUPPORTED_STATE.Vermont]: 'Vermont',
  [SUPPORTED_STATE.Maine]: 'Maine',
  [SUPPORTED_STATE.RhodeIsland]: 'Rhode Island',
  [SUPPORTED_STATE.NewHampshire]: 'New Hampshire',
  [SUPPORTED_STATE.Kansas]: 'Kansas',
  [SUPPORTED_STATE.SouthDakota]: 'South Dakota',
  [SUPPORTED_STATE.Nebraska]: 'Nebraska',
  [SUPPORTED_STATE.NewMexico]: 'New Mexico',
  [SUPPORTED_STATE.Idaho]: 'Idaho',
  [SUPPORTED_STATE.Nevada]: 'Nevada',
  [SUPPORTED_STATE.NorthDakota]: 'North Dakota',
  [SUPPORTED_STATE.Iowa]: 'Iowa',
  [SUPPORTED_STATE.Washington]: 'Washington',
  [SUPPORTED_STATE.Oregon]: 'Oregon',
  [SUPPORTED_STATE.Hawaii]: 'Hawaii',
  [SUPPORTED_STATE.Alaska]: 'Alaska',
  [SUPPORTED_STATE.Utah]: 'Utah',
  [SUPPORTED_STATE.Wyoming]: 'Wyoming',
  [SUPPORTED_STATE.NewYork]: 'New York',
  [SUPPORTED_STATE.DistrictOfColumbia]: 'District of Columbia',
};

export const SUPPORTED_STATES = Object.values(SUPPORTED_STATE);

/* When updating also updates
libs\o8-utils\src\lib\lead-and-quote\quote-form.utils.ts
getLeadSourceCode and getLeadSourceFullName
*/
export enum SUPPORTED_LEAD_SOURCE {
  Rocketer = 'r',
  Origin8Cares = 'o',
  HappyCog = 'hc',
  Klever = 'klever',
}
/* When updating also updates
libs\o8-utils\src\lib\lead-and-quote\quote-form.utils.ts
getLeadSourceCode and getLeadSourceFullName
*/
export const SUPPORTED_LEAD_SOURCES = Object.values(SUPPORTED_LEAD_SOURCE);

export enum KEYBOARD_NAVIGATION {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  ESCAPE = 'Escape',
  ENTER = 'Enter',
  TAB = 'Tab',
}

export interface RadioButtonOption<T> {
  value: T;
  label: string;
}

export interface DropdownOption<T> {
  value: T | null;
  label: string;
}

export interface PhoneParams {
  product: Product;
  leadSource: SUPPORTED_LEAD_SOURCE;
  state?: SUPPORTED_STATE;
  carrier: InsuranceProvider | undefined;
  whiteLabel: WhiteLabelPartner | undefined;
}

export const COLPENN_FINAL_SUPPORTED_STATES = [
  SUPPORTED_STATE.Arizona,
  SUPPORTED_STATE.Illinois,
  SUPPORTED_STATE.Pennsylvania,
  SUPPORTED_STATE.Ohio,
  SUPPORTED_STATE.NorthCarolina,
  SUPPORTED_STATE.Texas,
  SUPPORTED_STATE.NewJersey,
  SUPPORTED_STATE.Oklahoma,
  SUPPORTED_STATE.Tennessee,
  SUPPORTED_STATE.Wisconsin,
  SUPPORTED_STATE.SouthCarolina,
  SUPPORTED_STATE.Colorado,
  SUPPORTED_STATE.Connecticut,
  SUPPORTED_STATE.Indiana,
  SUPPORTED_STATE.Kentucky,
  SUPPORTED_STATE.Louisiana,
  SUPPORTED_STATE.Virginia,
  SUPPORTED_STATE.Maryland,
  SUPPORTED_STATE.Minnesota,
  SUPPORTED_STATE.Michigan,
  SUPPORTED_STATE.Missouri,
  SUPPORTED_STATE.Alabama,
  SUPPORTED_STATE.Georgia,
  SUPPORTED_STATE.California,
  SUPPORTED_STATE.Mississippi,
  SUPPORTED_STATE.Florida,
  SUPPORTED_STATE.WestVirginia,
  SUPPORTED_STATE.Arkansas,
  SUPPORTED_STATE.Vermont,
  SUPPORTED_STATE.RhodeIsland,
  SUPPORTED_STATE.Maine,
  SUPPORTED_STATE.NewHampshire,
  SUPPORTED_STATE.Kansas,
  SUPPORTED_STATE.SouthDakota,
  SUPPORTED_STATE.Nebraska,
  SUPPORTED_STATE.NewMexico,
  SUPPORTED_STATE.Idaho,
  SUPPORTED_STATE.Montana,
  SUPPORTED_STATE.Nevada,
  SUPPORTED_STATE.NorthDakota,
  SUPPORTED_STATE.Iowa,
  SUPPORTED_STATE.Washington,
  SUPPORTED_STATE.Oregon,
  SUPPORTED_STATE.Delaware,
  SUPPORTED_STATE.DistrictOfColumbia,
];

export const PGL_TERM_SUPPORTED_STATES = [
  SUPPORTED_STATE.Alaska,
  SUPPORTED_STATE.Washington,
  SUPPORTED_STATE.Oregon,
  SUPPORTED_STATE.Hawaii,
  SUPPORTED_STATE.California,
  SUPPORTED_STATE.Arizona,
  SUPPORTED_STATE.Idaho,
  SUPPORTED_STATE.Montana,
  SUPPORTED_STATE.Colorado,
  SUPPORTED_STATE.Utah,
  SUPPORTED_STATE.Wyoming,
  SUPPORTED_STATE.Nevada,
];

export const MYGA_FG_SUPPORTED_STATES = [
  SUPPORTED_STATE.Alabama,
  SUPPORTED_STATE.Arizona,
  SUPPORTED_STATE.California,
  SUPPORTED_STATE.Colorado,
  SUPPORTED_STATE.Connecticut,
  SUPPORTED_STATE.Florida,
  SUPPORTED_STATE.Georgia,
  SUPPORTED_STATE.Illinois,
  SUPPORTED_STATE.Indiana,
  SUPPORTED_STATE.Iowa,
  SUPPORTED_STATE.Kansas,
  SUPPORTED_STATE.Kentucky,
  SUPPORTED_STATE.Louisiana,
  SUPPORTED_STATE.Maine,
  SUPPORTED_STATE.Maryland,
  SUPPORTED_STATE.Massachusetts,
  SUPPORTED_STATE.Michigan,
  SUPPORTED_STATE.Minnesota,
  SUPPORTED_STATE.Missouri,
  SUPPORTED_STATE.Mississippi,
  SUPPORTED_STATE.Nebraska,
  SUPPORTED_STATE.Nevada,
  SUPPORTED_STATE.NewJersey,
  SUPPORTED_STATE.NorthCarolina,
  SUPPORTED_STATE.Ohio,
  SUPPORTED_STATE.Oregon,
  SUPPORTED_STATE.Pennsylvania,
  SUPPORTED_STATE.RhodeIsland,
  SUPPORTED_STATE.SouthCarolina,
  SUPPORTED_STATE.SouthDakota,
  SUPPORTED_STATE.Tennessee,
  SUPPORTED_STATE.Texas,
  SUPPORTED_STATE.Utah,
  SUPPORTED_STATE.Virginia,
  SUPPORTED_STATE.Washington,
  SUPPORTED_STATE.WestVirginia,
  SUPPORTED_STATE.Wisconsin,
];

export const ASSURITY_DISABILITY_INCOME_SUPPORTED_STATES = [
  SUPPORTED_STATE.Virginia,
  SUPPORTED_STATE.Tennessee,
  SUPPORTED_STATE.Kentucky,
  SUPPORTED_STATE.NorthCarolina,
  SUPPORTED_STATE.SouthCarolina,
  SUPPORTED_STATE.Georgia,
  SUPPORTED_STATE.Mississippi,
  SUPPORTED_STATE.Alabama,
  SUPPORTED_STATE.Arkansas,
  SUPPORTED_STATE.Texas,
  SUPPORTED_STATE.Florida,
  SUPPORTED_STATE.Wisconsin,
  SUPPORTED_STATE.Minnesota,
  SUPPORTED_STATE.Indiana,
  SUPPORTED_STATE.Ohio,
  SUPPORTED_STATE.Pennsylvania,
  SUPPORTED_STATE.Utah,
  SUPPORTED_STATE.California,
];

const filterOutExcludedStates = (excludedList: SUPPORTED_STATE[]) => {
  return SUPPORTED_STATES.filter((s) => excludedList.every((excludedState) => excludedState !== s));
};

export const EXCLUDED_HAVEN_LIFE_STATES = [
  SUPPORTED_STATE.NewYork,
  SUPPORTED_STATE.Delaware,
  SUPPORTED_STATE.NorthDakota,
  SUPPORTED_STATE.SouthDakota,
];
export const EXCLUDED_WN_STATES = [
  SUPPORTED_STATE.Alaska,
  SUPPORTED_STATE.Arkansas,
  SUPPORTED_STATE.Connecticut,
  SUPPORTED_STATE.DistrictOfColumbia,
  SUPPORTED_STATE.Hawaii,
  SUPPORTED_STATE.Iowa,
  SUPPORTED_STATE.Idaho,
  SUPPORTED_STATE.Kansas,
  SUPPORTED_STATE.Louisiana,
  SUPPORTED_STATE.Massachusetts,
  SUPPORTED_STATE.Maine,
  SUPPORTED_STATE.Mississippi,
  SUPPORTED_STATE.Montana,
  SUPPORTED_STATE.NorthDakota,
  SUPPORTED_STATE.Nebraska,
  SUPPORTED_STATE.NewHampshire,
  SUPPORTED_STATE.NewMexico,
  SUPPORTED_STATE.Nevada,
  SUPPORTED_STATE.NewYork,
  SUPPORTED_STATE.Oklahoma,
  SUPPORTED_STATE.Oregon,
  SUPPORTED_STATE.RhodeIsland,
  SUPPORTED_STATE.SouthDakota,
  SUPPORTED_STATE.Utah,
  SUPPORTED_STATE.Vermont,
  SUPPORTED_STATE.Washington,
  SUPPORTED_STATE.WestVirginia,
  SUPPORTED_STATE.Wyoming,
  SUPPORTED_STATE.Michigan,
];

export const HAVEN_LIFE_TERM_STATES = filterOutExcludedStates(EXCLUDED_HAVEN_LIFE_STATES);
export const WASHINGTON_NATIONAL_STATES = filterOutExcludedStates(EXCLUDED_WN_STATES);
export const NATIONWIDE_TERM_STATES = filterOutExcludedStates([SUPPORTED_STATE.NewYork]);
export const LEGAL_AND_GENERAL_TERM_STATES = filterOutExcludedStates([SUPPORTED_STATE.NewYork]);

export enum WhiteLabelPartner {
  AAA = 'aaa', // This partner is not used anymore and kept for reference and testing purposes only
  PGL = 'pgl',
}

export const WHITE_LABEL_PARTNERS = Object.values(WhiteLabelPartner);

export interface O8GqlResult<T extends object> {
  results?: T[];
  count?: number;
  sum?: Partial<T>;
}
