import {
  ApolloClient,
  ApolloClientOptions,
  ApolloQueryResult,
  FetchResult,
  MutationOptions,
  QueryOptions,
} from '@apollo/client/core';
import { Observable } from 'rxjs';
import { GraphqlClient } from './graphql-client.interface';

// Simple wrapper around ApolloClient so we use observable instead of Promises
export const createGraphqlClient = (options: ApolloClientOptions<any>): GraphqlClient => {
  const apolloClient = new ApolloClient(options);
  const query = (options: QueryOptions<any, any>): Observable<ApolloQueryResult<any>> => {
    return new Observable((subscriber) => {
      apolloClient
        .query(options)
        .then((res) => {
          subscriber.next(res);
          subscriber.complete();
        })
        .catch((e) => {
          subscriber.error(e);
        });
    });
  };
  const mutate = (
    options: MutationOptions<any, any, any, any>,
  ): Observable<FetchResult<any, Record<string, any>, Record<string, any>>> => {
    return new Observable((subscriber) => {
      apolloClient
        .mutate(options)
        .then((res) => {
          subscriber.next(res);
          subscriber.complete();
        })
        .catch((e) => {
          subscriber.error(e);
        });
    });
  };
  return { ...apolloClient, query, mutate } as GraphqlClient;
};
