import { O8ConfigService } from '../config';
import { Provider } from '@angular/core';
import { createApollo } from './grahql-client-factory';
import { GraphQlUtils } from './graphql.utils';
import { GRAPHQL_CONFIG, GraphqlConfig, GRAPHQL_CLIENT_LOADER } from './graphql-client.interface';

const configFactory = (
  configService: O8ConfigService<{ anonymousGraphQlGateway: string; securedGraphQlGateway: string }>,
) => {
  return () =>
    ({
      anonymousGraphqlEndpoint: configService.get('anonymousGraphQlGateway') ?? '',
      securedGraphqlEndpoint: configService.get('securedGraphQlGateway') ?? '',
    } as GraphqlConfig);
};

export const withApolloGqlProviders = (config?: GraphqlConfig): Provider[] => {
  const configProvider = config
    ? { provide: GRAPHQL_CONFIG, useValue: () => config }
    : { provide: GRAPHQL_CONFIG, useFactory: configFactory, deps: [O8ConfigService] };
  return [
    GraphQlUtils,
    configProvider,
    { provide: GRAPHQL_CLIENT_LOADER, useFactory: createApollo, deps: [GraphQlUtils, GRAPHQL_CONFIG] },
  ];
};
