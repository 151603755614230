import { ApolloLink } from '@apollo/client/core';
import { OperationTypeNode } from 'graphql';

export const getCleanTypeNameMiddleware = () =>
  new ApolloLink((operation, forward) => {
    const definition = operation?.query?.definitions.filter((def) => def.kind === 'OperationDefinition')?.[0];
    if (definition?.kind == 'OperationDefinition' && definition?.operation === OperationTypeNode.MUTATION) {
      const omitTypename = (key: string, value: string) => (key === '__typename' ? undefined : value);
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation);
  });
