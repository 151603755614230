import { setContext } from '@apollo/client/link/context';
import { O8_CUSTOM_HEADERS } from 'common.interfaces';

import { GraphQlUtils } from '../graphql-client/graphql.utils';

export const getTrackerMiddleware = (graphQlUtils: GraphQlUtils) =>
  setContext(async (_, { headers = {} }) => {
    const requestId = graphQlUtils.getRequestId();
    const sessionId = graphQlUtils.getSessionId();
    const token = await graphQlUtils.getImpersonationToken();
    return {
      headers: {
        ...headers,
        [O8_CUSTOM_HEADERS.TRACE_ID]: requestId,
        [O8_CUSTOM_HEADERS.SESSION_ID]: sessionId,
        [O8_CUSTOM_HEADERS.IMPERSONATION]: token,
      },
    };
  });
