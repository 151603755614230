export enum BROWSER_EXT_MESSAGE_TYPE {
  SF_NAVIGATION = 'o8:sf-navigation',
}

export const O8_BROWSER_EXTENSION = 'o8-browser-extension';

export interface BrowserExtMessagePayload {
  type: BROWSER_EXT_MESSAGE_TYPE;
  data: object;
}

export interface BrowserExtMessagePayloadSfNavigation {
  path: string;
  basePath: string;
}

export type O8ExtensionWindow = Window & { o8ExtensionhasRun?: boolean };
