import { Injectable, inject } from '@angular/core';
import { APP_CONFIG } from '../../shared-providers';
import { Observable, map, filter, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class O8ConfigService<T extends object> {
  private conf = inject(APP_CONFIG) as T;

  get<K extends keyof T>(key: K): T[K] | undefined {
    return this.conf?.[key] ?? undefined;
  }

  get$<K extends keyof T>(key: K): Observable<T[K]> {
    return of(this.conf).pipe(
      filter((config) => !!config),
      map((config) => (config as T)[key]),
    );
  }
}
