import { gql } from '@apollo/client';

export const getO8UserExtraInfo = gql`
  query O8UserExtraInfo {
    o8UserExtraInfo {
      talkdeskUserId
    }
  }
`;

export const getUsersBaseInfo = gql`
  query UserProfiles($filters: O8RootFiltersInput) {
    userProfileResults(filters: $filters) {
      results {
        id
        email
        name
        firstName
        lastName
        timezone
        talkdeskUserId
        licensedStates
        wtLicensedStates
        approvedProducts
        authorizations {
          permissions
          roles
        }
        wtSkills {
          name
        }
        advocateLevel
      }
    }
  }
`;
